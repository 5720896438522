import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';

import { RootStoreState } from '@store/root';
import { CartStoreSelectors } from '@store/root/client';
import { CartStoreActions } from '@store/root/client/cart';
import { CartItemModel } from '@app/models/client/cart/cart-item.model';
import { IsPlatformService } from '@services/client/is-platform/is-platform.service';

@Injectable({
  providedIn: 'root',
})
export class CartSessionStorageService {
  readonly unsubscribe = new Subject<void>();

  constructor(
    private store: Store<RootStoreState.State>,
    private isPlatformService: IsPlatformService,
  ) {}

  watchCartSateAndUpdateStorage() {
    this.store
      .pipe(
        select(CartStoreSelectors.getCurrentTicketsState),
        takeUntil(this.unsubscribe),
      )
      .subscribe((cartState) => {
        if (cartState) {
          this.setItem('cart', JSON.stringify(cartState));
        }
      });
  }

  setCartFromStorage() {
    const cart: CartItemModel[] = this.getItem('cart');
    if (cart && cart[0] && cart[0].eventId) {
      this.store.dispatch(
        new CartStoreActions.SetCart({ currentTickets: cart }),
      );
    }
  }

  cleanStorageCart() {
    this.removeItem('cart');
  }

  setItem(name: string, data: any) {
    if (this.isPlatformService.isBrowser()) {
      return sessionStorage.setItem(name, data);
    }
  }

  getItem(name: string) {
    if (this.isPlatformService.isBrowser()) {
      if (sessionStorage.getItem(name)) {
        return JSON.parse(sessionStorage.getItem(name));
      }
    }
  }

  removeItem(name: string) {
    return sessionStorage.removeItem(name);
  }
}
